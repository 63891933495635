document.addEventListener("DOMContentLoaded", function () {
  var currentDate = new Date();
  var endDate = new Date("2023-05-12T00:00:00");

  if (currentDate < endDate) {
    var popup = document.getElementById("popup");
    var closeBtn = document.getElementsByClassName("close")[0];

    popup.style.display = "block";

    closeBtn.onclick = function () {
      popup.style.display = "none";
    };

    window.onclick = function (event) {
      if (event.target === popup) {
        popup.style.display = "none";
      }
    };
  }
});
